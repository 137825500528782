import {React, useState, useEffect} from "react";
import HomeFooter from "./HomeFooter";
import engineer from "../../assets/images/mystic6.avif";
import {Helmet} from "react-helmet";
import TopAppBarEn from "./TopAppBar";



function LazyBackgroundImageKnowledge({img}) {
  const [loaded, setLoaded] = useState(false);
  const handleLoad = () => {
    setLoaded(true);
  };
return (
 <div className="h-full"
    style={{
      backgroundImage: `url("${img}")`,
      backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center",
      filter: loaded ? "none" : "blur(20px)",
      transition: "filter 0.5s",
    }}>
    <img src={img} alt="" onLoad={handleLoad} style={{ display: "none" }} />
    {loaded}

    <div className="flex flex-col items-center pt-24">
            <h1 className="font-bold text-white text-3xl" >
                Dreams Gist
            </h1>
      <p className="text-white">Detailed dreams interpretations.</p>
      <p className="text-white">Daily, weekly, and monthly horoscopes.</p>
    </div>


    <div className="md:container md:mx-auto justify-center flex justify-center items-center px-auto" >
      <div className="flex flex-col justify-center items-start row-start-2 sm:row-start-1 py-12 px-6">
        <br /><br /><br />
        <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium text-white leading-normal">
        <br />  <strong></strong>
        </h1><br /><br />
        <p className="text-xl text-white mt-4 mb-6">
          <br /> <br /><br />
        </p>  
        </div>
          <div className="flex w-full">
            <div className="h-full w-full" >
            </div>
          </div>
    </div>
    <div className="flex flex-col items-center py-6 ">
            <p className="text-lg text-base text-white text-xl font-semibold italic">
                <span className="text-orange-400">
                  <a href="/dreams/a" className="btn btn-primary btn m-1">Dreams </a> </span> & 
                 <span className="text-sky-600"> <a href="/horoscope/en" className="btn btn-secondary btn m-1">Horoscope</a> </span>
            </p>

    </div>
    <div className="py-6">

    </div>
 </div>
);
}



function HomeDescription(){
  return (
    <>
<div className="text-base-content  pt-12  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="py-2 lg:py-4">
    <div className="py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
      <h2 className="flex justify-center items-center sm:text-2xl text-xl m-4 pb-6 text-purple-500 ">
        <b>Why should someone care about dreams' meaning?</b>
      </h2>

      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Understanding the Language of Dreams</h3>
      <p>Dreams are undeniably part of our sleeping minds. 
        In some dreams, we have the life we think we deserve, where we are happy and surrounded by a beautiful environment and good people. 
        In other dreams, we are trapped in front of our dippest fears and saddest emotions. 
        Dreams have captivated the human imagination since the dawn of civilization. 
        They weave together emotions, memories, and symbols into various stories. 
        One must understand the language of dreams to know which story a dream is about. 
        Yes, dreams are a language that one has to learn. </p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      What Are Dreams?</h3>
      <p>From a scientific perspective, dreams are a series of images, emotions, and sensations that occur during specific stages of sleep, 
        particularly during Rapid Eye Movement (REM) sleep. However, they represent far more than mere neural activity – they're windows into our deepest thoughts, fears, desires, and unresolved conflicts.</p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
        The Pioneers of Dream Research</h3>
      <p>The study of dreams has attracted brilliant minds throughout history. 
        Sigmund Freud revolutionized our understanding with his seminal work "The Interpretation of Dreams" (1900), arguing that dreams were the "royal road to the unconscious." 
        Carl Jung expanded on this foundation, introducing concepts like the collective unconscious and universal archetypes that appear in dreams across cultures.
      Modern researchers like J. Allan Hobson challenged traditional psychoanalytic views with his activation-synthesis hypothesis, while Calvin Hall's content analysis of thousands of dreams revealed common patterns in dream themes across different populations.</p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Remarkable Dream Stories</h3>
      <p>History is rich with examples of dreams inspiring great achievements. Dmitri Mendeleev claimed the periodic table came to him in a dream. 
        Paul McCartney composed "Yesterday" after hearing the melody in his sleep. Mary Shelley's "Frankenstein" was inspired by a vivid nightmare. 
        These instances suggest dreams can be powerful catalysts for creativity and problem-solving.</p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Why Dream Interpretation Matters</h3>
      <p>
        &bull; <b>Self-Discovery:</b> Dreams often reveal aspects of ourselves we might not consciously recognize<br />
        &bull; <b>Problem-Solving: </b> Dreams can offer new perspectives on challenges we face<br />
        &bull; <b>Emotional Processing: </b> They help us process difficult emotions and experiences<br />
        &bull; <b>Pattern Recognition:</b> Recurring dreams may highlight important themes in our lives<br />
        &bull; <b>Personal Growth:</b> Dream work can facilitate psychological and spiritual development<br />
      </p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Learning the Language</h3>
      <p>Each dream is unique to the dreamer, colored by personal experiences and cultural context. While some symbols may have universal meanings, the true significance of a dream often lies in the dreamer's personal associations and current life situation.
By learning to interpret your dreams, you're not just decoding nightly stories – you're embarking on a journey of self-discovery, accessing an inner wisdom that's been speaking to you all along. Whether you're seeking answers, working through challenges, or simply curious about the mysteries of your mind, dream interpretation offers a fascinating path to deeper self-understanding.
<b> Begin your journey into the world of dreams. Your unconscious mind has stories to tell – are you ready to listen?</b>
</p>
    </div>

    <div className="py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
      <h2 className="flex justify-center items-center sm:text-2xl text-xl pb-6 m-4 text-purple-500 ">
        <b>What is the impact of astrology on our daily lives?</b>
      </h2>

      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Understanding Astrology
        </h3>
      <p>Since ancient times, humans have looked to the stars for guidance, finding patterns and meaning in the movement of stars, 
        planets, and other celestial bodies. Astrology – the study of how planetary movements relate to events and personalities on Earth – has evolved from ancient wisdom into a modern tool for self-reflection and personal insight.</p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      What Is Astrology?
        </h3>
      <p>Astrology is based on the principle that the positions of the Sun, Moon, planets, 
        and constellations at the time of our birth create a unique cosmic blueprint that influences our personalities and life paths. 
        This ancient practice combines mathematical precision with symbolic interpretation to create a framework for understanding ourselves and our place in the universe.</p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      The Zodiac Wheel
        </h3>
      <p>
      The zodiac consists of twelve distinct signs, each associated with specific traits, elements, and ruling planets: <br />
      &bull; <b>Fire Signs (Aries, Leo, Sagittarius)</b>: Energy and passion <br />
      &bull; <b>Earth Signs (Taurus, Virgo, Capricorn)</b>: Stability and practicality<br />
      &bull; <b>Air Signs (Gemini, Libra, Aquarius)</b>: Intellect and communication<br />
      &bull; <b>Water Signs (Cancer, Scorpio, Pisces)</b>: Emotion and intuition
      </p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      The Power of Horoscopes
        </h3>
      <p>
      Horoscopes are personalized interpretations of current planetary positions and their potential influence on different aspects of life. They serve as: <br />
      &bull; Daily guidance for decision-making<br />
      &bull; Tools for understanding personal patterns<br />
      &bull; Insights into relationship dynamics<br />
      &bull; Indicators of favorable times for various activities<br />
      &bull; Sources of inspiration and self-reflection
      </p>

      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Historical Significance
        </h3>
      <p>
      Throughout history, astrology has influenced cultures worldwide. 
      Ancient civilizations from Mesopotamia to China developed sophisticated astrological systems. 
      Notable figures like Ptolemy advanced astronomical-astrological understanding, while Renaissance courts employed astrologers as trusted advisors. 
      Even Carl Jung, the renowned psychologist, incorporated astrological concepts into his work on archetypes and personality.
      </p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Modern Applications
        </h3>
      <p>
      Today's astrology combines traditional wisdom with contemporary understanding: <br />
      &bull; Personal Growth: Understanding your birth chart can illuminate natural strengths and challenges <br />
      &bull; Timing: Awareness of planetary cycles can help in planning important life events <br />
      &bull; Relationships: Synastry (chart comparison) offers insights into interpersonal dynamics <br />
      &bull; Career Guidance: Astrological insights can help align career choices with natural talents <br />
      &bull; Emotional Understanding: Lunar cycles and planetary transits can explain emotional patterns
      </p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Why Follow Your Horoscope?
        </h3>
      <p>
      Regular horoscope reading offers multiple benefits: <br />
      &bull; Provides a framework for daily reflection <br />
      &bull; Offers different perspectives on personal challenges <br />
      &bull; Creates awareness of natural cycles and timing <br />
      &bull; Encourages self-discovery and personal growth <br />
      &bull; Connects you with ancient wisdom in a modern context
      </p>
      <h3 className="flex justify-center items-center sm:text-xl text-lg m-4 text-blue-500 font-bold">
      Begin Your Cosmic Journey
        </h3>
      <p>Whether you're seeking daily guidance, deeper self-understanding, or connection with cosmic rhythms, 
        astrology offers a rich tradition of wisdom. Your daily, weekly, 
        and monthly horoscopes are more than predictions – they're tools for navigating life's journey with greater awareness and purpose.
      <b> Discover what the stars hold for you. Your cosmic story is written in the heavens – let us help you read it. </b>  </p>



    </div>



  </div>
</div>
;

    </>
  )
}









function Home () {
  return(
    <>
    
    <TopAppBarEn></TopAppBarEn>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Dreams Gist Home page</title>
        <meta name="description" content="Instantly get detailed interpretations of dreams on our website Dreams Gist." />              
        <meta name="keywords" content="dreams interpretation,horoscope"/>
        <meta property="og:title" content="Dreams Gist Home page" />        
        <meta property="og:description" content="Instantly get detailed interpretations of dreams on our website Dreams Gist." />        
        <meta property="og:site_name" content="Dreams Gist Home page" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>



            <LazyBackgroundImageKnowledge img={engineer}></LazyBackgroundImageKnowledge>
            <HomeDescription   />
        <HomeFooter></HomeFooter>
    </>
  )
}
export default Home;