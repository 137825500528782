import React from "react";
import {Helmet} from "react-helmet";
import TopAppBarEn from "../en/TopAppBar";
function AboutEn() {
    return (
      <>
      <TopAppBarEn></TopAppBarEn>

        <Helmet>
          <meta charSet="utf-8" />
          <title>About us</title>
          <meta name="description" content="Why we built the Dreams Gist website." />              
          <meta name="keywords" content="about us"/>
          <meta property="og:title" content="About us" />        
          <meta property="og:description" content="Why we built the Dreams Gist website." />        
          <meta property="og:site_name" content="About us" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
        </Helmet>


          <br /><br />
          <div className="py-6 sm:py-12">
            <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
              <div className="mx-auto max-w-md px-4">
                <h1><b> About</b></h1>
                <div className="divide-y divide-gray-300/50">
                  <div className="space-y-6 py-8 text-base leading-7 text-gray-600 dark:text-white">
                    <p> <span className="font-bold text-purple-500">
                      Dreams Gist </span>gathers a vast collection of dreams and their detailed interpretation. 
                      Dreams reflect our deepest thoughts, fears, and desires, in the form of symbolic imagery. 
                      By building this website, <b>We</b> want to provide a tool for people to know themselves better. 
                      A tool that will constantly warn you to be more careful in your daily life. 
                      A tool that will give you optimism when you face hardship. 
                      A tool that continually tells you another way is possible, that a better life awaits you. But, who are <b>We </b> ?</p>
                    <p> 
                      We, refers to <a className="text-blue-400 hover:text-blue-600 font-bold" href="https://arfusoft.com/" target="_blank" rel="noreferrer">Arfusoft</a> , our software development company that designed and implemented this website.
                    </p>
                  </div>
                </div>
              </div>
            </div>
      </div>

  
      </>
  
    );
  }
  export default AboutEn;