import {React, useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom';
import TopAppBarFr from "../fr/TopAppBarFr";
import {HoroscopeViewDayFr,HoroscopeViewWeekFr } from "../../components/HoroscopeView"
import dayjs from "dayjs";

function FrHoroscope() {
  //var users = [];
  let { signe } = useParams();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items_day, setItemsDay] = useState([]);
  const [items_week, setItemsWeek] = useState([]);
  const [items_month, setItemsMonth] = useState([]);
  useEffect(() => {
    const url_day = `https://kayoo123.github.io/astroo-api/jour.json`;
    fetch(url_day)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItemsDay(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );

      const url_week = `https://kayoo123.github.io/astroo-api/hebdomadaire.json`;
      fetch(url_week)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItemsWeek(result);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );

        const url_month = `https://kayoo123.github.io/astroo-api/mensuel.json`;
        fetch(url_month)
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setItemsMonth(result);
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )

  }, [signe])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {

  return (
    <>
    <TopAppBarFr></TopAppBarFr>

    <Helmet>
          <meta charSet="utf-8" />
          <title>Horoscope de ton signe zodiaque</title>
          <meta name="description" content="Horoscope détaillé du jour et de la semaine pour ton signe zodiaque." />              
          <meta name="keywords" content="signe zodiaque,horoscope,horoscope du jour,horoscope de la semaine"/>
          <meta property="og:title" content="Horoscope de ton signe zodiaque" />        
          <meta property="og:description" content="Horoscope détaillé du jour et de la semaine pour ton signe zodiaque." />        
          <meta property="og:site_name" content="Horoscope de ton signe zodiaque" />
          <meta property="og:locale" content="fr_FR" />
          <meta property="og:type" content="article" />
        </Helmet>

      <div className="text-base-content  pt-12  flex justify-center items-center  bg-base-100 mainDiv" >
          <div className="py-4 lg:py-6">
            <div className="space-y-6 py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
                <h1 className="  flex justify-center items-center text-lg"><b>Horoscope du signe zodiaque </b>   </h1>
                <h2 className=" text-purple-700 flex justify-center items-center text-lg"><b>{signe.charAt(0).toUpperCase()+signe.slice(1)}</b>   </h2>
            </div>
            <div className="text-base-content bg-base-100 px-4 py-6">
                    {(signe === 'balance') &&  
                            <div>
                                <HoroscopeViewDayFr 
                                    signe={signe} date={dayjs(items_day.date+" UTC").format("DD-MMM-YYYY")} horoscope_day={items_day.balance}>
                                </HoroscopeViewDayFr>
                                <HoroscopeViewWeekFr 
                                    horoscope_week={items_week.balance}>
                                </HoroscopeViewWeekFr>
                            </div>
                    }
                    {(signe === 'belier') &&  
                            <div>
                                <HoroscopeViewDayFr 
                                    signe={signe} date={dayjs(items_day.date+" UTC").format("DD-MMM-YYYY")} horoscope_day={items_day.belier}>
                                </HoroscopeViewDayFr>
                                <HoroscopeViewWeekFr 
                                    horoscope_week={items_week.belier}>
                                </HoroscopeViewWeekFr>
                            </div>
                    }
                    {(signe === 'cancer') &&  
                            <div>
                                <HoroscopeViewDayFr 
                                    signe={signe} date={dayjs(items_day.date+" UTC").format("DD-MMM-YYYY")} horoscope_day={items_day.cancer}>
                                </HoroscopeViewDayFr>
                                <HoroscopeViewWeekFr 
                                    horoscope_week={items_week.cancer}>
                                </HoroscopeViewWeekFr>
                            </div>
                    }   
                    {(signe === 'capricorne') &&  
                            <div>
                                <HoroscopeViewDayFr 
                                    signe={signe} date={dayjs(items_day.date+" UTC").format("DD-MMM-YYYY")} horoscope_day={items_day.capricorne}>
                                </HoroscopeViewDayFr>
                                <HoroscopeViewWeekFr 
                                    horoscope_week={items_week.capricorne}>
                                </HoroscopeViewWeekFr>
                            </div>
                    }
                    {(signe === 'gemeaux') &&  
                            <div>
                                <HoroscopeViewDayFr 
                                    signe={signe} date={dayjs(items_day.date+" UTC").format("DD-MMM-YYYY")} horoscope_day={items_day.gemeaux}>
                                </HoroscopeViewDayFr>
                                <HoroscopeViewWeekFr 
                                    horoscope_week={items_week.gemeaux}>
                                </HoroscopeViewWeekFr>
                            </div>
                    }
                    {(signe === 'lion') &&  
                            <div>
                                <HoroscopeViewDayFr 
                                    signe={signe} date={dayjs(items_day.date+" UTC").format("DD-MMM-YYYY")} horoscope_day={items_day.lion}>
                                </HoroscopeViewDayFr>
                                <HoroscopeViewWeekFr 
                                    horoscope_week={items_week.lion}>
                                </HoroscopeViewWeekFr>
                            </div>
                    } 
                    {(signe === 'poissons') &&  
                            <div>
                                <HoroscopeViewDayFr 
                                    signe={signe} date={dayjs(items_day.date+" UTC").format("DD-MMM-YYYY")} horoscope_day={items_day.poissons}>
                                </HoroscopeViewDayFr>
                                <HoroscopeViewWeekFr 
                                    horoscope_week={items_week.poissons}>
                                </HoroscopeViewWeekFr>
                            </div>
                    }
                    {(signe === 'sagittaire') &&  
                            <div>
                                <HoroscopeViewDayFr 
                                    signe={signe} date={dayjs(items_day.date+" UTC").format("DD-MMM-YYYY")} horoscope_day={items_day.sagittaire}>
                                </HoroscopeViewDayFr>
                                <HoroscopeViewWeekFr 
                                    horoscope_week={items_week.sagittaire}>
                                </HoroscopeViewWeekFr>
                            </div>
                    } 
                    {(signe === 'scorpion') &&  
                            <div>
                                <HoroscopeViewDayFr 
                                    signe={signe} date={dayjs(items_day.date+" UTC").format("DD-MMM-YYYY")} horoscope_day={items_day.scorpion}>
                                </HoroscopeViewDayFr>
                                <HoroscopeViewWeekFr 
                                    horoscope_week={items_week.scorpion}>
                                </HoroscopeViewWeekFr>
                            </div>
                    } 
                    {(signe === 'taureau') &&  
                            <div>
                                <HoroscopeViewDayFr 
                                    signe={signe} date={dayjs(items_day.date+" UTC").format("DD-MMM-YYYY")} horoscope_day={items_day.taureau}>
                                </HoroscopeViewDayFr>
                                <HoroscopeViewWeekFr 
                                    horoscope_week={items_week.taureau}>
                                </HoroscopeViewWeekFr>
                            </div>
                    }
                    {(signe === 'verseau') &&  
                            <div>
                                <HoroscopeViewDayFr 
                                    signe={signe} date={dayjs(items_day.date+" UTC").format("DD-MMM-YYYY")} horoscope_day={items_day.verseau}>
                                </HoroscopeViewDayFr>
                                <HoroscopeViewWeekFr 
                                    horoscope_week={items_week.verseau}>
                                </HoroscopeViewWeekFr>
                            </div>
                    } 
                    {(signe === 'vierge') &&  
                            <div>
                                <HoroscopeViewDayFr 
                                    signe={signe} date={dayjs(items_day.date+" UTC").format("DD-MMM-YYYY")} horoscope_day={items_day.vierge}>
                                </HoroscopeViewDayFr>
                                <HoroscopeViewWeekFr 
                                    horoscope_week={items_week.vierge}>
                                </HoroscopeViewWeekFr>
                            </div>
                    }                                                                                                                                                                                                 
            </div>
        </div>
      </div>
      
    </>
  );}
  }





  
  export default  FrHoroscope;