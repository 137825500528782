import {Helmet} from "react-helmet";
import React from "react";

export default function HomeFooter() {
  return (
    <footer className="relative   pt-8 pb-6">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-4/12 px-2">
            <span className="block uppercase text-black-500 text-sm font-bold mb-2">
              Company
            </span>
            <ul className="list-unstyled">
              <li>
                <a className="text-black-500  block pb-2 text-sm"
                  href="/about">About
                </a>
              </li>
              <li>
                <a className="text-black-500  block pb-2 text-sm"
                  href="/contact">Contact
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-4/12 py-4 px-2">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12  ml-auto">
                <span className="block uppercase text-black-500 text-sm font-bold mb-2">
                  Resources
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-black-500  block pb-2 text-sm"
                        href="https://facilemath.com/" target="_blank" rel="noreferrer">Facile Math
                    </a>
                  </li>
                  <li>
                    <a className="text-black-500  block pb-2 text-sm"
                        href="https://alleramusic.com/" target="_blank" rel="noreferrer">All Era Music
                    </a>
                  </li>
                  <li>
                    <a className="text-black-500  block pb-2 text-sm"
                        href="https://libpeople.com/" target="_blank" rel="noreferrer">Library of the People
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-2">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 ml-auto">
                <span className="block uppercase text-black-500 text-sm font-bold mb-2">
                  Legal
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-black-500  block pb-2 text-sm"
                        href="/policy" >Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a className="text-black-500  block pb-2 text-sm"
                        href="/cookies" >Cookies Policy
                    </a>
                  </li>                  
                  <li>
                    <a className="text-black-500  block pb-2 text-sm"
                        href="/terms" >Terms of use
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>


        </div>
        <hr className="my-6 border-grey-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full  px-4 mx-auto text-center">
            <div className="text-sm text-black-500  py-1">
              Copyright © {new Date().getFullYear()}{" "}Dreams Gist by{" "}
              <a
                href="https://arfusoft.com/"
                className="text-blue-500 hover:text-blue-900 font-bold"
              >
                Arfusoft
              </a>.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
