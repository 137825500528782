import {React, useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom';
import TopAppBarEn from "../en/TopAppBar";
 import {DreamViewText, DreamViewImage} from "../DreamView"; 


function EnDreams() {
  let { category } = useParams();  
  const [searchItem, setSearchItem] = useState('')
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const url = `https://goldfish-app-asx3v.ondigitalocean.app/dream/en/start/${category}`;
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [category])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {

  return (
    <>
    <TopAppBarEn></TopAppBarEn>

      <Helmet>
          <meta charSet="utf-8" />
          <title>Dreams interpretations</title>
          <meta name="description" content="Get the detailed interpretations of thousands of dreams." />              
          <meta name="keywords" content="dream,Dream interpretation,dream meaning"/>
          <meta property="og:title" content="Dreams interpretations" />        
          <meta property="og:description" content="Get the detailed interpretations of thousands of dreams." />        
          <meta property="og:site_name" content="Dreams interpretations" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
      </Helmet>

     
      {(category === 'a') && 
        <div className="text-base-content  pt-12  flex justify-center items-center  bg-base-100 mainDiv" >
        <div className="py-4 lg:py-6">

        <div className="py-2 lg:py-4 text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
        <h1 className="flex justify-center items-center sm:text-2xl text-xl m-4 text-purple-500 "><b>Dreams</b></h1><br /><br />
        <h2 className="text-black-500 dark:text-white font-bold">Dreams act as windows into our subconscious.</h2>
        <p>They reveal the raw material of our deepest thoughts, fears, and desires, often woven into a narrative of symbolic imagery and events.
          Understanding the symbolism and hidden messages in dreams can help you utilize them as a tool for personal growth. 
          Dream interpretation enables you to navigate your inner landscape and uncover hidden aspects of your psyche. 
          Our dreams provide insight into the hidden corners of our minds, reflecting our repressed emotions, unresolved conflicts, and secret desires. 
          They are unfiltered expressions of our inner world, tailored by our subconscious mind into stories that capture our psychological state.
          Understanding Dream Symbolism and Hidden Messages  Dreams speak a language of symbols where each object, person, and scenario represents an aspect of our waking life. 
          Deciphering this symbolic language is like deciphering a complex puzzle, providing deep insight into our emotions, relationships, and life experiences.</p><br /><br />
          <h2 className="text-black-500 dark:text-white font-bold">Benefits of Studying the Meaning of Dreams for Self-Development </h2>
          <p>Studying dreams can contribute significantly to our self-development.
          Interpreting dreams can help us better understand our fears, worries, aspirations, and potential. 
          This self-awareness gives us the strength to face our inner conflicts and accept our true selves. 
          Below you will find a list of dreams and their detailed interpretations.</p>
                <p className="text-purple-700 text-base">
          All the dreams starting with that character are displayed when you click on a letter, such as 
                  <code className="text-sm font-bold"> A, L, I, J, </code>etc. 
                </p>
                <p>
                <span>The native Application can be downloaded on the different applications stores: </span>
                          <a className="btn btn-ghost btn-circle btn-sm mr-4" href="https://play.google.com/store/apps/details?id=tntechnology.app.dreams" target={"_blank"} rel="noopener noreferrer">
                              <svg width="20px" height="20px" viewBox="19.932999999999996 68.509 228.155 228.155" xmlns="http://www.w3.org/2000/svg"><path d="M101.885 207.092c7.865 0 14.241 6.376 14.241 14.241v61.09c0 7.865-6.376 14.24-14.241 14.24-7.864 0-14.24-6.375-14.24-14.24v-61.09c0-7.864 6.376-14.24 14.24-14.24z" fill="#a4c639"/><path d="M69.374 133.645c-.047.54-.088 1.086-.088 1.638v92.557c0 9.954 7.879 17.973 17.66 17.973h94.124c9.782 0 17.661-8.02 17.661-17.973v-92.557c0-.552-.02-1.1-.066-1.638H69.374z" fill="#a4c639"/><path d="M166.133 207.092c7.865 0 14.241 6.376 14.241 14.241v61.09c0 7.865-6.376 14.24-14.241 14.24-7.864 0-14.24-6.375-14.24-14.24v-61.09c0-7.864 6.376-14.24 14.24-14.24zM46.405 141.882c7.864 0 14.24 6.376 14.24 14.241v61.09c0 7.865-6.376 14.241-14.24 14.241-7.865 0-14.241-6.376-14.241-14.24v-61.09c-.001-7.865 6.375-14.242 14.241-14.242zM221.614 141.882c7.864 0 14.24 6.376 14.24 14.241v61.09c0 7.865-6.376 14.241-14.24 14.241-7.865 0-14.241-6.376-14.241-14.24v-61.09c0-7.865 6.376-14.242 14.241-14.242zM69.79 127.565c.396-28.43 25.21-51.74 57.062-54.812h14.312c31.854 3.073 56.666 26.384 57.062 54.812H69.79z" fill="#a4c639"/><path d="M74.743 70.009l15.022 26.02M193.276 70.009l-15.023 26.02" fill="none" stroke="#a4c639" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/><path d="M114.878 102.087c.012 3.974-3.277 7.205-7.347 7.216-4.068.01-7.376-3.202-7.388-7.176v-.04c-.011-3.975 3.278-7.205 7.347-7.216 4.068-.011 7.376 3.2 7.388 7.176v.04zM169.874 102.087c.012 3.974-3.277 7.205-7.347 7.216-4.068.01-7.376-3.202-7.388-7.176v-.04c-.011-3.975 3.278-7.205 7.347-7.216 4.068-.011 7.376 3.2 7.388 7.176v.04z" fill="#ffffff"/></svg>
                              </a>
                              <a className="btn btn-ghost btn-circle btn-sm" href="https://www.microsoft.com/store/apps/9NX4RB7S5HKW" target={"_blank"} rel="noopener noreferrer">
                              <svg width="20px" height="20px" viewBox="-0.5 0 257 257" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><path d="M0 36.357L104.62 22.11l.045 100.914-104.57.595L0 36.358zm104.57 98.293l.08 101.002L.081 221.275l-.006-87.302 104.494.677zm12.682-114.405L255.968 0v121.74l-138.716 1.1V20.246zM256 135.6l-.033 121.191-138.716-19.578-.194-101.84L256 135.6z" fill="#00ADEF"/></svg>
                              </a> . <span  className="text-black-500 dark:text-white"></span> 
                </p>
  
              </div>
          </div>
        </div>
        
      }
      {(category !== 'a') && <div className="py-12"></div>}
      <div className="text-base-content bg-base-100 flex justify-center items-center">
          <ul className="btn-group px-4">
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/a">A</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/b">B</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/c">C</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/d">D</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/e">E</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/f">F</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/g">G</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/h">H</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/i">I</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/j">J</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/k">K</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/l">L</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/m">M</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/n">N</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/o">O</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/p">P</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/q">Q</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/r">R</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/s">S</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/t">T</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/u">U</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/v">V</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/w">W</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/y">Y</a></div></li>
            <li><div className="px-1"><a className="btn btn-outline btn-circle btn-xs" href="/dreams/z">Z</a></div></li>
          </ul>
        </div>


      <div className="text-base-content bg-base-100 px-2 py-2 flex justify-center items-center">
        <input  type="text" placeholder="Search here" className="mt-4 input input-rounded input-secondary w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
      </div>

      <div className="text-base-content bg-base-100 px-4 py-6">
        
        {
        items.filter((dream) => //items here
        {
          if(searchItem === "") {
            return dream;
          }
          else if 
          (dream.Word.toLowerCase().includes(searchItem.toLowerCase())) {
            return dream;
          }
          else return null
          
        }).map(dream => 
        
          dream.ImageUrl === "" ?
        <DreamViewText key={dream.Id} id={dream.Id} 
          word={dream.Word}
          interpretation={dream.Interpretation}
          ></DreamViewText>
        :
        <DreamViewImage key={dream.Id} id={dream.Id} 
          word={dream.Word}
          interpretation={dream.Interpretation}
          image={dream.ImageUrl}
          ></DreamViewImage>

        )
        }
      </div>
    </>
  );}
  }
  export default  EnDreams;